<template>
  <div id="openAccount">
    <div class="content_box">
      <div class="inner_max">
        <div class="link_box">
          <ul>
            <li class="active">
              <a @click="getDepositBlacklist()" class="el-button" data-testid="depositFunds">
                {{ $t('common.button.depositFunds') }}
              </a>
              <em class="icon el-icon-arrow-right"></em>
            </li>
            <li>
              <a @click="getWithdrawalBlacklist()" class="el-button" data-testid="withdrawFunds">
                {{ $t('common.button.withdrawFunds') }}
              </a>
              <em class="icon el-icon-arrow-right"></em>
            </li>
            <li v-if="openAdditionAccountEnabled">
              <a @click="$router.push('openLiveAccount')" class="el-button" data-testid="openLiveAccount">
                {{ $t('menu.openAdditAcc') }}
              </a>
              <em class="icon el-icon-arrow-right"></em>
            </li>
            <li>
              <div>
                <a @click.stop.prevent="$router.push('downloads')" class="el-button" data-testid="downloads">
                  {{ $t('common.button.download') }}
                </a>
                <em class="icon el-icon-arrow-right"></em>
              </div>
            </li>
          </ul>
        </div>
        <h3>{{ $t('openDemoAdditAcc.header') }}</h3>
        <OpenDemoAccountTemplate> </OpenDemoAccountTemplate>
      </div>
    </div>
  </div>
</template>

<script>
import OpenDemoAccountTemplate from '@/components/template/OpenDemoAccountTemplate';
import openAccountMixin from '@/mixins/openAccount';

export default {
  components: {
    OpenDemoAccountTemplate
  },
  mixins: [openAccountMixin]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/account/openAccount.scss';
</style>
