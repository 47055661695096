<template>
  <el-card class="openAccount">
    <div class="form_box demo_box" v-if="!success">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <ul>
          <li>
            <p class="title">{{ $t('openAdditAcc.chooseTradePlat') }}</p>
            <div class="box box_platform">
              <div class="box_inner">
                <ul>
                  <el-form-item prop="tradingPlatform">
                    <li
                      v-for="item in $platform.openLiveAccount.choosePlatform(regulator)"
                      :key="item.value"
                      @click.prevent.stop="
                        form.tradingPlatform = item.value;
                        form.accountType = null;
                      "
                      :class="{ active: form.tradingPlatform == item.value, popular: item.value === 'mt5' }"
                      :data-testid="item.value"
                    >
                      <img class="mt_img" :src="item.img" alt />
                    </li>
                  </el-form-item>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <p class="title">{{ $t('openAdditAcc.chooseAccType') }}</p>
            <div class="box box_type">
              <div class="box_inner">
                <el-form-item prop="accountType">
                  <ul>
                    <li
                      v-for="item in liveTypes"
                      :key="item.value"
                      @click.prevent.stop="form.accountType = item.value"
                      :class="{ active: form.accountType === item.value }"
                      :data-testid="item.value"
                    >
                      <img class="mt_img" :src="item.img" alt="" />
                    </li>
                  </ul>
                </el-form-item>
              </div>
            </div>
          </li>
          <li>
            <p class="title">{{ $t('openAdditAcc.chooseCurrency') }}</p>
            <div class="box box_currency">
              <div class="box_inner">
                <el-form-item prop="currency">
                  <ul>
                    <li
                      v-for="item in $platform.openLiveAccount.chooseCurrency(regulator)"
                      :key="item.currency"
                      @click.prevent.stop="form.currency = item.currency"
                      :class="{ active: form.currency === item.currency }"
                      :data-testid="item.currency"
                    >
                      <div class="shadow_inner">
                        <img :src="item.img" @click.prevent.stop="form.currency = item.currency" alt="" />
                        <p>{{ item.value }}</p>
                      </div>
                    </li>
                  </ul>
                </el-form-item>
              </div>
            </div>
          </li>
          <li>
            <p class="title">{{ $t('openAdditAcc.chooseLeverage') }}</p>
            <div class="box box_default">
              <div class="box_inner">
                <el-form-item prop="leverage">
                  <ul>
                    <li
                      v-for="item in $platform.openLiveAccount.chooseLeverage(regulator)"
                      :key="item"
                      @click.prevent.stop="form.leverage = item"
                      :class="{ active: form.leverage === item }"
                      class="radio"
                      :data-testid="item"
                    >
                      <div :class="{ active: form.leverage === item }">
                        <p>{{ item }} : 1</p>
                      </div>
                    </li>
                  </ul>
                </el-form-item>
              </div>
            </div>
          </li>
          <li>
            <p class="title">{{ $t('openAdditAcc.chooseBalance') }}</p>
            <div class="box box_default">
              <div class="box_inner">
                <el-form-item prop="balance">
                  <ul class="center_sort">
                    <li
                      v-for="item in $platform.openLiveAccount.chooseBalance(regulator)"
                      :key="item.value"
                      @click.prevent.stop="form.balance = item.value"
                      :class="{ active: form.balance === item.value }"
                      class="radio"
                      :data-testid="item.value"
                    >
                      <div :class="{ active: form.balance == item.value }">
                        <p>{{ item.name }}</p>
                      </div>
                    </li>
                  </ul>
                </el-form-item>
              </div>
            </div>
          </li>
        </ul>

        <div class="text-center mt-5">
          <el-button class="bg-primary" @click="submitClick" data-testid="submit">{{
            $t('common.button.submit')
          }}</el-button>
        </div>
      </el-form>
    </div>
    <Result backUrl="homeDemo" v-else>
      <div v-html="$t('openDemoAdditAcc.succ')"></div>
    </Result>
  </el-card>
</template>
<script>
import Result from '@/components/Result';
import { apiCreateDemoAccount } from '@/resource';

export default {
  name: 'OpenDemoAccount',
  components: { Result },
  data() {
    return {
      form: {
        tradingPlatform: `mt${this.$route.query.p}`,
        accountType: null,
        currency: null,
        leverage: null,
        balance: null
      },
      rules: {
        accountType: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.accTypeErr') }],
        currency: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.currError') }],
        leverage: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.leverageErr') }],
        balance: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.balanceErr') }]
      },
      success: false
    };
  },
  computed: {
    liveTypes() {
      if (this.form.tradingPlatform === 'mt4') {
        return this.$platform.openLiveAccount.mt4DemoChooseType(this.regulator);
      } else {
        return this.$platform.openLiveAccount.mt5DemoChooseType(this.regulator);
      }
    }
  },
  methods: {
    createDemoAccount() {
      return apiCreateDemoAccount({ ...this.form });
    },
    submitClick() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.createDemoAccount().then(resp => {
            if (resp.data.code === 0) this.success = true;
          });
        }
      });
    }
  }
};
</script>
